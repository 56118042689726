import React from "react";
import PropTypes from "prop-types";
import Layout from "../../../../layouts/Layout";
import Seo from "../../../seo";
import Intro from "../../../common/Intro";
import Breadcrumb from "../../../common/Breadcrumb";
import BasicSection from "../../../common/BasicSection";
import BlankLink from "../../../common/BlankLink";
import { getHrefs } from "../../../../utils/langService";
import { getShareImage } from "../../../../utils/getShareImage";

import imgTranslations from "../../../../images/translations.jpg";
import imgTranslator from "../../../../images/translator.jpg";

const Translations = props => {
  const { lang, t, layoutT, servicesT, postsT } = props.pageContext;
  const { pathname } = props.location;

  const { pages } = getHrefs(lang);

  return (
    <Layout t={layoutT} servicesT={servicesT} posts={postsT} lang={lang} pathname={pathname}>
      <Seo title={t.seo.title} lang={lang} description={t.seo.description} image={getShareImage(lang)} />

      <Intro title={t.intro.title} />

      <Breadcrumb lang={lang} actualPage={t.seo.title} pages={[{ name: servicesT.additional.title }]} />

      <div className="AdditionalServices">
        <BasicSection t={t.mainSection} image={imgTranslations} link={`/${lang}/${pages[3]}/`}>
          <BlankLink href={`/${t.translations}.pdf`} className="link-blue link-blue--withMargin">
            {t.mainSection.see}
          </BlankLink>
        </BasicSection>

        <BasicSection t={t.sworn} image={imgTranslator} reverse />
      </div>
    </Layout>
  );
};

Translations.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Translations;
